.my-perm-bg-clr1 {
    background: #4fc2c0 !important;
}

.headColor {
    color: #4fc2c0 !important;
}

.credit-clr {
    color: #b5cc47;
}

.chkbx-layout {
    border: 1.2px solid white !important;
    border-radius: 10px !important;
}

.chkbx-layout:after {
    border: solid white !important;
}

.blue-clr {
    color: #4fc2c0 !important;
}

.border-input {
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    border-right: white;
    border-left: 1.2px solid #525564;
    border-bottom: 1.2px solid #525564;
    border-top: 1.2px solid #525564;
}

.height-fit-content {
    height: fit-content;
}

#phaseLevel .mat-step-label {
    width: 100%;
}

.gray-bg-clr {
    background-color: #bdbdbd !important;
}

.selectable:hover {
    cursor: pointer;
}

.teal-clr-hvr:hover {
    color: #4fc2c0 !important;
}

.teal-bg-clr {
    background-color: #4fc2c0 !important;
}

.teal-clr {
    color: #4fc2c0 !important;
}

.p-25px {
    padding: 25px !important;
}

.p-blr-25px {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 25px !important;
}

.fs-20px {
    font-size: 20px !important;
}

.gray-pill-border {
    border-radius: 60px !important;
    border: 2px solid #bdbdbd;
}

.white-clr {
    color: #fff;
}

.kt-portlet {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    background-color: #ffffff;
    margin-bottom: 20px;
    border-radius: 4px; 
}
.kt-portlet .kt-portlet__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;    
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }

.btn-cmd-ctr {
    background: linear-gradient(to right, #294a75 12%, #3c839f 60%, #4fc2c0 100%) !important;
    border: white !important;
    color: white;
}

app-phase .kt-checkbox > span:after {
    border: solid #3f4047;
}

app-phase .dropdown-menu.dropdown-menu-lg {
    width: calc(100% - 4%) !important;
    left: 12px !important;
}

.hr-plcy-gray {
    border-top: 1.2px solid lightgray !important;
    border-top-color: lightgray;
    border-top-style: solid;
    border-top-width: 1px;
}

.fs-icon {
    font-size: 12px !important;
}

.my-plcy-bg-clr1 {
    background: #4fc2c0 !important;
}

.btn-circle-plcy.btn-sm {
    width: 30px;
    height: 30px;
    border-radius: 35px;
    font-size: 14px;
    line-height: 1.33;
}

.blur-clr {
    color: #4fc2c0 !important;
}

.fs-x-small {
    font-size: x-small !important;
}

.lh-9 {
    line-height: 9px !important;
}

.level-layout {
    background-color: white !important;
    color: #3f4047 !important;
    border-radius: 30px !important;
    border: 2px solid #3f4047 !important;
    font-size: 10px !important;
    font-weight: 700 !important;
}

span.dropdown-item:active {
    background: white !important;
    color: #525564 !important;
}

.level-layout-two {
    background-color: white !important;
    color: #525564 !important;
    border-radius: 30px !important;
    border: 1.2px solid #525564 !important;
}

.title-btn {
    padding-left: 10px !important;
    padding-right: 8px !important;
    height: 41px !important;
}
/*placeholder*/
.level-layout::-webkit-input-placeholder {
    font-weight: bold !important;
}

.level-layout::-moz-placeholder {
    font-weight: bold !important;
}

.level-layout:-ms-input-placeholder {
    font-weight: bold !important;
}

.element::-webkit-input-placeholder {
    font-weight: bold !important;
}

.element::-moz-placeholder {
    font-weight: bold !important;
}

.element::-ms-input-placeholder {
    font-weight: bold !important;
}

mat-horizontal-stepper::placeholder {
    text-align: left;
    font-weight: bold !important;
    font-size: x-small;
}

.font-size-10 {
    font-size: 10px !important;
}

.bg-default-app {
    background: #d1d3d3 !important;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #525564;
    font-weight: 700;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #525564;
    font-weight: bold;
}

@media (max-width: 1350px) {
    .h-auto-cust {
        height: auto !important;
    }
}

.border-radius-25 {
    border-radius: 25px !important;
}

.green-clr {
    color: #b5cc47 !important;
}

@media (max-width: 1350px) {
    .h-auto-cust {
        height: auto !important;
    }
}

@media (max-width: 767px) {
    .margin-syn-4 {
        margin-top: 4rem !important;
    }

    .mat-horizontal-content-container {
        padding: 0 !important;
    }
}

.PhaseLevelTooltip2 .tooltip-inner {
    border-radius: 10px !important;
    border: 2px solid #595959 !important;
    word-wrap: break-word !important;
    background: #595959 !important;
    padding: 5px;
    padding-top: 5px !important;
    text-align: center;
    color: white;
    width: 150px !important;
    font-weight: 800;
    font-size: 8px !important;
    height: 110px !important;
}

.PhaseLevelTooltip .tooltip-inner {
    border-radius: 10px !important;
    border: 2px solid #595959 !important;
    word-wrap: break-word !important;
    background: #595959 !important;
    padding: 5px;
    padding-top: 5px !important;
    text-align: center;
    color: white;
    width: 150px !important;
    font-weight: 800;
    font-size: 8px !important;
    height: 60px !important;
}

.customPolicyTooltip-synopsis .tooltip-inner {
    border-radius: 20px !important;
    border: 2px solid #595959 !important;
    word-wrap: break-word !important;
    background: #595959 !important;
    padding: 5px;
    padding-top: 5px !important;
    text-align: center;
    color: white;
    width: 150px;
    font-weight: 800;
    font-size: 8px !important;
    height: 110px !important;
}

.product .tooltip-inner {
    border-radius: 25px !important;
    border: 2px solid #4fc2c0 !important;
    word-wrap: break-word !important;
    background: white;
    color: #3f4047;
    width: 250px !important;
    font-weight: 800;
    text-align: left !important;
    font-size: 10px !important;
    height: auto !important;
    margin-bottom: -10px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
}

.product .arrow::before {
    visibility: hidden;
  }

.border-black-2 {
    border: 2px solid #595959 !important;
}

.max-width-400px {
    max-width: 400px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .mt-pl-4 {
        margin-top: 1.5rem !important;
    }

    div.txt-desk-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .mt-pl-4 {
        margin-top: 1.5rem !important;
    }

    div.txt-desk-center {
        text-align: center !important;
    }

    .desk-pt-2 {
        padding-top: 0.5rem !important;
    }
}

@media (min-width: 1200px) {
    .mt-pl-4 {
        margin-top: 1.5rem !important;
    }

    div.txt-desk-center {
        text-align: center !important;
    }

    .desk-pt-2 {
        padding-top: 0.5rem !important;
    }
}

#phaseLeveltwo .mat-horizontal-stepper-header-container,
#phaseLevelThree .mat-horizontal-stepper-header-container,
#phaseLevelThree .mat-horizontal-stepper-header-container,
#personalinformation .mat-horizontal-stepper-header-container,
#appdisclaimerslevel .mat-horizontal-stepper-header-container {
    background: #d1d3d3 !important;
}

#phaseLeveltwo
    > .mat-horizontal-stepper-header-container
    > .mat-step-header[aria-selected="true"]
    > .mat-step-label.mat-step-label-active,
#phaseLevelfive
    > .mat-horizontal-stepper-header-container
    > .mat-step-header[aria-selected="true"]
    > .mat-step-label.mat-step-label-active,
#phaseLevelThree
    > .mat-horizontal-stepper-header-container
    > .mat-step-header[aria-selected="true"]
    > .mat-step-label.mat-step-label-active,
#personalinformation
    > .mat-horizontal-stepper-header-container
    > .mat-step-header[aria-selected="true"]
    > .mat-step-label.mat-step-label-active,
#appdisclaimerslevel
    > .mat-horizontal-stepper-header-container
    > .mat-step-header[aria-selected="true"]
    > .mat-step-label.mat-step-label-active {
    color: #4fc2c0 !important;
}

#phaseLeveltwo > .mat-horizontal-stepper-header-container > .mat-step-header[aria-selected="true"],
#phaseLevelfive > .mat-horizontal-stepper-header-container > .mat-step-header[aria-selected="true"],
#phaseLevelThree > .mat-horizontal-stepper-header-container > .mat-step-header[aria-selected="true"],
#personalinformation > .mat-horizontal-stepper-header-container > .mat-step-header[aria-selected="true"],
#appdisclaimerslevel > .mat-horizontal-stepper-header-container > .mat-step-header[aria-selected="true"] {
    border-bottom: #4fc2c0 1px solid !important;
}

#phaseLeveltwo > .mat-horizontal-stepper-header-container > .mat-step-header[aria-selected="false"],
#phaseLevelfive > .mat-horizontal-stepper-header-container > .mat-step-header[aria-selected="false"],
#phaseLevelThree > .mat-horizontal-stepper-header-container > .mat-step-header[aria-selected="false"],
#personalinformation > .mat-horizontal-stepper-header-container > .mat-step-header[aria-selected="false"],
#appdisclaimerslevel > .mat-horizontal-stepper-header-container > .mat-step-header[aria-selected="false"] {
    border-bottom: #3f4047 1px solid !important;
}

.mat-step-icon .mat-icon {
    display: none !important;
}

.mat-stepper-horizontal-line {
    display: none !important;
}

.mat-horizontal-stepper-header .mat-step-icon {
    display: none !important;
}

.mat-horizontal-content-container {
    background: rgb(208, 210, 210) !important;    
}

@media screen and (max-width: 1350px) {
    .mr-resize {
        margin-top: 6px !important;
    }

    button.pad-resize {
        padding-left: 11px !important;
        padding-right: 11px !important;
    }
}

.btn-phase {
    background: #4fc2c0;
    border: white !important;
    color: white;
}

.fs-xx-small {
    font-size: xx-small !important;
}

.font-size-9 {
    font-size: 9px;
}

.PI input[type="radio"] {
    display: none;
}

.PI input[type="radio"] + label span {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: transparent;
    vertical-align: middle;
    border: 2px solid #4fc2c0;
    border-radius: 50%;
    padding: 2px;
    margin: -4px 5px;
}

.PI input[type="radio"]:checked + label span {
    width: 18px;
    width: 18px;
    height: 18px;
    background: #4fc2c0;
    background-clip: content-box;
}

.PI input[type="radio"] + label::before {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: transparent;
    vertical-align: middle;
    border: 2px solid #4fc2c0;
    border-radius: 50%;
    padding: 2px;
    margin: -4px 5px;
}

.PI input[type="radio"] + label::after {
    width: 18px;
    width: 18px;
    height: 18px;
    background: #4fc2c0;
    background-clip: content-box;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-10 {
    font-size: 10px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.pre-preend-round {
    border-top-left-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
    border: 1.2px #595959 solid !important;
    background-color: white !important;
    color: #525564 !important;
    font-weight: 800 !important;
    font-size: 10px !important;
    border-right-color: white !important;
}

.slider {
    height: 19px !important;
}

.slider:before {
    width: 36px !important;
    border-radius: 12px;
    top: 1px !important;
}

.m-switch.m-switch--sm input:checked ~ span:after {
    margin-left: 18px !important;
}

.slider:after {
    height: 16px !important;
    width: 16px !important;
    top: 2px !important;
    bottom: 2px !important;
}

input:empty + .slider:before {
    background-color: darkgray !important;
}

input:checked + .slider:before {
    background-color: #4fc2c0 !important;
}

.input-group > .form-control:not(:first-child) {
    border-left: 0px !important;
}

.input-group-prepend {
    margin-left: -2px !important;
}

.bg-black {
    background-color: #3f4047 !important;
}

.m-portlet .portlet-height {
    height: 3.1rem !important;
}

/* #region Personal Info*/
@media (max-width: 550px) {
    #phaseLevelfive > .mat-horizontal-stepper-header-container,
    #personalinformation > .mat-horizontal-stepper-header-container,
    #phaseLevelThree > .mat-horizontal-stepper-header-container,
    #phaseLeveltwo > .mat-horizontal-stepper-header-container {
        display: block;
        overflow: auto;
        height: 70px;
    }
}
/* #endregion */

.marg-top {
    margin-top: 4rem !important;
}

#phaseLevel > .mat-horizontal-stepper-header-container {
    background: #f3eeea !important;
}

#phaseLevel .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header {
    border-right: 2px solid lightgray !important;
}

#product-descriptions .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header {
    width: 100%;
}

#phaseLevel > .mat-horizontal-content-container {
    padding: 0 !important;
}

.levels:hover {
    background: #4fc2c0 !important;
}

#phaseLevel > .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header {
    background-color: inherit !important;
    cursor: default !important;
}

@media only screen and (min-width: 1025px) {
    #phaseLevel > .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header {
        padding: 0 10px;
    }
}

mat-horizontal-stepper {
    background: #d1d3d3 !important;
}

#phaseLevel > .mat-horizontal-stepper-header-container > .mat-step-header[aria-selected="true"] {
    background: #4fc2c0 !important;
}

#phaseLevel
    > .mat-horizontal-stepper-header-container
    > .mat-step-header[aria-selected="true"]
    > .mat-step-label.mat-step-label-active {
    color: white;
}

#phaseLevel
    > .mat-horizontal-stepper-header-container
    > .mat-step-header[aria-selected="false"]
    > .mat-step-label.mat-step-label-active {
    color: lightgray;
}

#personalinfo
    > .mat-horizontal-stepper-header-container
    > .mat-step-header
    #phaseLevel
    > .mat-horizontal-stepper-header-container
    > .mat-step-header {
    align-items: flex-end !important;
}

@media (min-width: 768px) {
    #phaseLevel > .mat-horizontal-stepper-header-container > .mat-step-header {
        width: 15%;
    }

    #personalinfo > .mat-horizontal-stepper-header-container > .mat-step-header {
        width: 25%;
    }
}

.dropdown-toggle:focus {
    z-index: 2 !important;
}

.dropdown-menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

.dropdown-menu::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

.dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #000000;
}

#medicalsteps .mat-horizontal-stepper-header-container {
    overflow: auto !important;
    width: 84vw !important;
}

#medicalsteps .mat-horizontal-stepper-header-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #d1d3d3;
}

#medicalsteps .mat-horizontal-stepper-header-container::-webkit-scrollbar {
    width: 12px;
    background-color: #d1d3d3;
}

#medicalsteps .mat-horizontal-stepper-header-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: black;
}

#medicalsteps .mat-horizontal-stepper-header.mat-step-header {
    overflow: initial !important;
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
    background: #4fc2c0 !important;
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
    background: #4fc2c0 !important;
}

/*product*/

.blue-border {
    border: 1.2px solid #4fc2c0;
    color: #4fc2c0 !important;
}

.credit-border {
    border: 1.2px solid #b5cc47;
    color: #b5cc47;
}

.estate-border {
    border: 1.2px solid #264971;
    color: #264971;
}

.PLFour-badge > .m-badge.m-badge--plcy {
    background-color: #4fc2c0;
    color: #fff;
    border-radius: 1.75rem !important;
}

app-quote-wizard .btn.dropdown-toggle:after,
app-get-a-quote .btn.dropdown-toggle:after,
app-phase .btn.dropdown-toggle:after {
    color: #4fc2c0 !important;
    font-weight: 700 !important;
    margin: 7px 10px 0px 0px !important;
    float: right !important;
}

.dropdown-menu ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

.dropdown-menu ::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

.dropdown-menu ::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.width-100px {
    width: 100px !important;
}

.synposis {
    position: relative;
    margin-top: 8%;
    left: 6%;
    width: 40%;
}

.summary-width .sum-child {
    height: 55vh;
    overflow: auto;
}

.summary-width {
    width: 25vw;
    height: 60vh;
    overflow: hidden;
}

.summary-width {
    width: 25vw;
    height: 60vh;
    overflow: hidden;
}

.widthWordWrap {
    width: 50% !important;
    word-break: break-word !important;
}

.summary-width .sum-child::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

.summary-width .sum-child::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

.summary-width .sum-child::-webkit-scrollbar-thumb {
    background-color: #000000;
}

app-edit-banking-details .dropdown-toggle:not(.valuegiven),
app-phase .dropdown-toggle:not(.valuegiven),
app-get-a-quote .dropdown-toggle:not(.valuegiven),
app-quote-wizard .dropdown-toggle:not(.valuegiven),
app-my-command-centre .dropdown-toggle:not(.valuegiven) {
    color: #f05536 !important;
}

.level-layout h6:not(.valuegiven) {
    color: #f05536 !important;
}

app-edit-banking-details ::-webkit-input-placeholder,
app-phase ::-webkit-input-placeholder,
app-get-a-quote ::-webkit-input-placeholder,
app-quote-wizard ::-webkit-input-placeholder,
app-my-command-centre ::-webkit-input-placeholder {
    color: #f05536 !important;
}

app-edit-banking-details ::placeholder,
app-phase ::placeholder,
app-get-a-quote ::placeholder,
app-quote-wizard ::placeholder,
app-my-command-centre ::placeholder {
    color: #f05536 !important;
    opacity: 1; /* Firefox */
}

app-edit-banking-details :-ms-input-placeholder,
app-phase :-ms-input-placeholder,
app-get-a-quote :-ms-input-placeholder,
app-quote-wizard :-ms-input-placeholder,
app-my-command-centre :-ms-input-placeholder {
    color: #f05536 !important;
    opacity: 1; /* Firefox */
}

app-edit-banking-details ::-ms-input-placeholder,
app-phase ::-ms-input-placeholder,
app-get-a-quote ::-ms-input-placeholder,
app-quote-wizard ::-ms-input-placeholder,
app-my-command-centre ::-ms-input-placeholder {
    color: #f05536 !important;
}

.pb-0imp {
    padding-bottom: 0px !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: transparent !important;
    border: 2px solid #4fc2c0 !important;
    width: 21px !important;
    height: 21px !important;
    left: -13px !important;
    top: -4px !important;
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
    background-color: transparent !important;
    border: 2px solid #4fc2c0 !important;
    width: 21px !important;
    height: 21px !important;
    left: -13px !important;
    top: -4px !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none !important;
    background: #4fc2c0 !important;
    border-radius: 15px !important;
    padding: 6px !important;
}

.custom-control-label::after {
    position: absolute !important;
    top: 0rem !important;
    left: -0.69rem !important;
    display: block !important;
    width: 1rem !important;
    height: 1rem !important;
    content: "" !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 50% 50% !important;
}

label.custom-control-label.black-clr.d-flex.font-weight-bold.float-left {
    text-align: center !important;
    padding-left: 18px;
    font-size: 12px;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}

.height-max-content {
    height: max-content !important;
}
