/*Common css Data*/

span.permission-switch input:checked ~ span:after {
    background-color: white !important;
    color: #fff;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(113, 106, 202, 0.41);
    box-shadow: 0px 3px 20px 0px rgba(113, 106, 202, 0.41);
}

.m-widget4 .m-widget4__item .m-widget4__img {
    display: table-cell;
    vertical-align: top !important;
}

span.permission-switch input:empty ~ span:after {
    color: #f7f8fa;
    background-color: white;
    /*-webkit-box-shadow: 0px 3px 20px 0px rgba(113, 106, 202, 0.41);
    box-shadow: 0px 3px 20px 0px rgba(113, 106, 202, 0.41);*/
}

@media screen and (max-width: 600px) {
    div.content-between {
        justify-content: space-between;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.content-between {
        justify-content: space-between;
    }
}

@media (min-width: 1030px) and (max-width: 1366px) {
    .buttonInit {
        position: absolute;
        bottom: 0px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

/*
    app-claims .top {
        position: absolute;
        bottom: 0px;
        margin-bottom: 2.2rem;
    }*/
}

@media (min-width: 1024px) and (max-width: 1366px) {
    .buttonInit {
        position: absolute;
        bottom: 0px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
/*
    app-claims .top {
        position: absolute;
        bottom: 0px;
        margin-bottom: 2.2rem;
    }*/
}

@media (min-width: 1370px) {
    .buttonInit {
        position: absolute;
        bottom: 0px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .mt-desktop-16 {
        margin-top: -16px !important;
    }

/*    app-claims .top {
        position: absolute;
        bottom: 0px;
        margin-bottom: 2.2rem;
    }*/
}

#Phase .m-content, #claims .m-content {
    overflow: hidden;
}

@media (min-width: 992px) {
    .mt-desktop-16 {
        margin-top: -40px !important;
    }
}
